<template>
  <div class="question">
    <div class="form">
       <el-form :inline="true" size="small">
        <el-form-item label="标题">
          <el-input v-model="queryInfo.title" placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="queryInfo.is_show" placeholder="请选择">
            <el-option label="请选择" value="" />
            <el-option label="启用" value="1" />
            <el-option label="停用" value="0" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getQuestionList">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btns">
        <el-button size="small" type="primary" @click="handleAdd">新增</el-button>
        <el-button size="small" type="primary" @click="exportAll">导出</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      height="calc(100vh - 240px)"
    >
      <el-table-column type="index" label="序号" />
      <el-table-column label="标题" prop="title" show-overflow-tooltip />
      <el-table-column label="答案" prop="content" show-overflow-tooltip />
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_show">启用</el-tag>
          <el-tag v-else type="danger">停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="排序" prop="sort" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
          />
          <el-button
            v-if="!scope.row.necessary"
            icon="el-icon-delete"
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <!-- 模态 -->
    <el-dialog :title="formTitle" width="50%" :visible.sync="questionFormVisible">
      <el-form
        :model="questionForm"
        :rules="questionRules"
        ref="questionForm"
        size="medium"
        label-width="80px"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="questionForm.title" placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="答案" prop="content">
          <el-input v-model="questionForm.content" type="textarea" placeholder="请输入答案" />
        </el-form-item>
        <el-form-item label="是否展示" prop="is_show">
          <el-switch
            v-model="questionForm.is_show"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="questionForm.sort" :min="0" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="save">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getQuestionList,
  addQuestion,
  updateQuestion,
  deleteQuestion,
  exportAll
} from '@/services/question'

export default {
  name: 'Question',
  data () {
    return {
      tableData: [],
      total: 0,
      pageSizes: [10, 20, 30, 40, 50, 100],
      queryInfo: {},
      questionForm: {
        sort: 0
      },
      questionRules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入答案', trigger: 'blur' }
        ]
      },
      formTitle: '新增',
      formType: 'add',
      questionFormVisible: false
    }
  },
  mounted () {
    this.getQuestionList()
  },
  methods: {
    reset () {
      this.queryInfo = {}
      this.getQuestionList()
    },
    async getQuestionList () {
      try {
        const { data } = await getQuestionList(this.queryInfo)
        this.tableData = data
      } catch (e) {
        console.log(e)
      }
    },
    cancel () {
      this.questionForm = { sort: 0 }
      this.questionFormVisible = false
    },
    save () {
      this.$refs.questionForm.validate(async valid => {
        if (valid) {
          try {
            this.questionFormVisible = false
            const isShow = this.questionForm.is_show ? 1 : 0
            this.questionForm.is_show = isShow
            this.formType === 'add' ? await addQuestion(this.questionForm) : await updateQuestion(this.questionForm.id, this.questionForm)
            this.questionForm = { sort: 0 }
            this.getQuestionList()
            this.$message({
              type: 'success',
              message: `${this.formTitle}常见问题成功`
            })
          } catch (err) {
            this.$message({
              type: 'error',
              message: `${this.formTitle}常见问题失败`
            })
          }
        }
      })
    },
    handleAdd () {
      if (this.$refs.questionForm) {
        this.$refs.questionForm.resetFields()
      }
      this.formTitle = '新增'
      this.formType = 'add'
      this.questionFormVisible = true
    },
    handleEdit (index, row) {
      if (this.$refs.questionForm) {
        this.$refs.questionForm.resetFields()
      }
      this.formTitle = '编辑'
      this.formType = 'edit'
      this.questionForm = { ...row }
      this.questionFormVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('请确认操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteQuestion(row.id)
        this.getQuestionList()
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async exportAll () {
      const { data } = await exportAll()
      const blob = new Blob([data], { type: 'application/octet-stream,charset=UTF-8' })
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = `常见问题-${Date.now()}.xlsx`
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    }
  }
}
</script>

<style lang="scss" scoped>
.question {
  box-sizing: border-box;
  padding: 15px;
  .form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FAFAFA;
    box-sizing: border-box;
    margin-bottom: 15px;
    padding: 15px 0;
    .el-form .el-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
